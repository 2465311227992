import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import User from './User';

import firebaseConfig from '../firebase';

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);

export default function DashboardApp() {
  const navigate = useNavigate();
  // eslint-disable-next-line consistent-return
  onAuthStateChanged(auth, (user) => {
    if (!user) {
      navigate('/login', { replace: true });
    }
  });
  return <User />;
}
