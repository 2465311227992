/* eslint-disable */

// material
import {Card, Container, Stack, Typography} from '@mui/material';
// components
import Page from '../components/Page';



export default function Profile() {
  return (
    <Page title="Мой профиль" style={{ marginTop: 70 }}>
        <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Профиль
          </Typography>
        </Stack>
            <Typography variant="p">
                Данный раздел будет доступен в следующем обновлении
            </Typography>
      </Container>
    </Page>
  );
}
