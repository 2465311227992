/* eslint-disable */

import {filter} from 'lodash';
import {Icon} from '@iconify/react';
import {useEffect, useState} from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// material
import {
  Button,
  Card,
  Checkbox,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
// components
import {initializeApp} from 'firebase/app';
import {collection, getDocs, getFirestore} from 'firebase/firestore';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import {UserListHead, UserListToolbar, UserMoreMenu} from '../components/_dashboard/user';
//
import AddUser from '../components/AddUser';
import firebaseConfig from '../firebase';
import EditStatusClient from '../components/EditStatusClient';
import DocModalClient from '../components/DocModalClient';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'usertime', label: 'Дата', alignRight: false },
  { id: 'name', label: 'Ф.И.О', alignRight: false },
  { id: 'phone', label: 'Телефон', alignRight: false },
  { id: 'device', label: 'Устройство', alignRight: false },
  { id: 'imei', label: 'IMEI / Серийный номер', alignRight: false },
  { id: 'breaking', label: 'Заявленные неисправности', alignRight: false },
  { id: 'cost', label: 'Предварительная стоимость', alignRight: false },
  { id: 'completeness', label: 'Комплектность', alignRight: false },
  { id: 'status', label: 'Статус', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);
  const [clientsData, setClientsData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [selectedID, setSelectedID] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [docModalOpen, setDocModalOpen] = useState(false);
  const [docModalData, setDocModalData] = useState('');
  const [idEditStatus, setIdEditStatus] = useState('');
  const openDialogAddContact = () => setOpen(true);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const navigate = useNavigate();

  useEffect(() => {
    const fethData = async () => {
      const clientsCol = collection(db, 'clients');
      const clientsSnapshot = await getDocs(clientsCol);
      const clientsList = clientsSnapshot.docs.map((doc) => doc.data());
      setClientsData(clientsList);
    };
    fethData();
  }, []);

  const uPdate = () => {
    const fethData = async () => {
      const clientsCol = collection(db, 'clients');
      const clientsSnapshot = await getDocs(clientsCol);
      const clientsList = clientsSnapshot.docs.map((doc) => doc.data());
      setClientsData(clientsList);
    };
    fethData();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = clientsData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    setSelectedID((prevState) => [...prevState, id]);
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - clientsData.length) : 0;

  const filteredUsers = applySortFilter(clientsData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  function timeConverter(UNIX_timestamp) {
    function formatDate(date) {
      var dd = date.getDate();
      if (dd < 10) dd = '0' + dd;
      var mm = date.getMonth() + 1;
      if (mm < 10) mm = '0' + mm;
      var yy = date.getFullYear() % 100;
      if (yy < 10) yy = '0' + yy;
      return dd + '.' + mm + '.' + yy;
    }
    return formatDate(new Date(UNIX_timestamp.toDate()))
  }

  function statusSelector(status) {
    if (status === 'Завершен') {
      return 'success';
    }
    if (status === 'В Ожидании') {
      return 'warning';
    }
    return 'secondary';
  }

  const controlUpdate = () => {
    setSelected([]);
    uPdate();
  };

  const updateAfterStatus = () => {
    navigate('/', { replace: true });
  };

  const EditStatus = (event) => {
    if (event.modal) {
      setEditModalOpen(true);
      setIdEditStatus({ id: event.client, status: event.status });
    }
  };

  const EditStatusDoc = (event) => {
    if (event.modal) {
      setDocModalData(event.client);
      setDocModalOpen(true);
    }
  };
  return (
    <Page title="Рабочий стол" style={{marginTop: 70}}>
      <AddUser open={open} callback={setOpen} updateHandler={uPdate} />
      <EditStatusClient
        open={editModalOpen}
        id={idEditStatus}
        callback={setEditModalOpen}
        updateHandler={updateAfterStatus}
      />
      <DocModalClient
        open={docModalOpen}
        data={docModalData}
        callback={setDocModalOpen}
        updateHandler={updateAfterStatus}
      />
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Клиенты
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            onClick={openDialogAddContact}
            to="#"
            style={{ textTransform: 'none' }}
            startIcon={<Icon icon={plusFill} />}
          >
            Новый заказ
          </Button>
        </Stack>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            id={selected}
            updateHandler={controlUpdate}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={clientsData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, name, phone, imei, breaking, status, cost, completeness, addtime, device } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row" align="left">{timeConverter(addtime)}</TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2">{name.length > 0 ? name : '---'}</Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{phone.length > 0 ? phone : '---'}</TableCell>
                          <TableCell align="left">{device.length > 0 ? device : '---'}</TableCell>
                          <TableCell align="left">{imei.length > 0 ? imei : '---'}</TableCell>
                          <TableCell align="left">{breaking.length > 0 ? breaking : '---'}</TableCell>
                          <TableCell align="left">{cost.length > 0 ? `${cost} ₽` : '---'}</TableCell>
                          <TableCell align="left">{completeness.length > 0 ? completeness : '---'}</TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={statusSelector(status)}>
                              {status}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <UserMoreMenu
                              ClientIdentity={id}
                              ClientStatus={status}
                              statusModal={EditStatus}
                              statusDocModal={EditStatusDoc}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
            labelRowsPerPage="Отображать клиентов в таблице"
            component="div"
            count={clientsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
