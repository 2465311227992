const firebaseConfig = {
  apiKey: 'AIzaSyAylcrZivdcijZ0BGeIzZu94oxGWyx60jY',
  authDomain: 'gacrm-284cf.firebaseapp.com',
  projectId: 'gacrm-284cf',
  storageBucket: 'gacrm-284cf.appspot.com',
  messagingSenderId: '1030337241726',
  appId: '1:1030337241726:web:229951952cb1a14352ae2f'
};

export default firebaseConfig;
