/* eslint-disable */

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import AddUserForm from './AddUserForm';
import { initializeApp } from 'firebase/app';
import {getFirestore, doc, collection, setDoc, Timestamp} from 'firebase/firestore';
import firebaseConfig from "../firebase";



const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);


// TODO: здесь мы будем отправлять сохранение пользователя в базу
export default function AddUser({open, callback, updateHandler}) {
    const handleClose = () => callback(false);
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

    const uuidv4 = () => {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    const handleSaveToDatabase = (data) => {
        const AddData = async () => {
            const newClientsRef = doc(collection(db, "clients"));
            await setDoc(newClientsRef, {
                "addtime": Timestamp.fromDate(new Date()),
                "id": uuidv4(),
                "name": data[0].name.toString(),
                "phone": data[0].phone.toString(),
                "device": data[0].deviceDanger.toString(),
                "imei": data[0].imei.toString(),
                "cost": data[0].cost.toString(),
                "breaking": data[0].breaking.toString(),
                "completeness": data[0].completeness.toString(),
                "status": data[0].status.toString()
            });
        };
        AddData();
        updateHandler(true);
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="addUserDialogInCRM"
            >
                <DialogTitle>Создать заказ и добавить клиента в работу</DialogTitle>
                <DialogContent>
                    <DialogContentText id="addUserDialogInCRM">
                        <AddUserForm closeWindow={callback} saveData={handleSaveToDatabase}/>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}
