import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string
};

export default function SearchNotFound({ searchQuery = '', ...other }) {
  return (
    <Paper {...other}>
      {searchQuery.length > 0 && (
        <>
          <Typography gutterBottom align="center" variant="subtitle1" style={{ width: '100%' }}>
            Таких клиентов нет в базе
          </Typography>
          <Typography variant="body2" align="center" style={{ width: '100%' }}>
            Нет результатов для поиска &nbsp;
            <strong>&quot;{searchQuery}&quot;</strong>
          </Typography>
        </>
      )}
      {searchQuery.length === 0 && (
        <>
          <Typography gutterBottom align="center" variant="subtitle1" style={{ width: '100%' }}>
            База клиентов пока пуста, добавьте новый заказ
          </Typography>
        </>
      )}
    </Paper>
  );
}
