/* eslint-disable */

import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { initializeApp } from 'firebase/app';
import {collection, getFirestore, onSnapshot, query, where} from 'firebase/firestore';
import firebaseConfig from "../firebase";
import serverConfig from "../serverConfigs";
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import {Box} from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    transition: '0.3s',
    color: theme.palette.text.secondary,
    "&:hover": {
        backgroundColor: 'rgba(38,164,109,0.2)',
        transition: '0.3s',
        scale: '1.01'
    }
}));



const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);


// TODO: здесь мы будем отправлять сохранение пользователя в базу
export default function DocModalClient({open, callback, updateHandler, data}) {

    const handleClose = () => callback(false);
    function timeConverter(UNIX_timestamp) {
        function formatDate(date) {
            var dd = date.getDate();
            if (dd < 10) dd = '0' + dd;
            var mm = date.getMonth() + 1;
            if (mm < 10) mm = '0' + mm;
            var yy = date.getFullYear() % 100;
            if (yy < 10) yy = '0' + yy;
            return dd + '.' + mm + '.' + yy;
        }
        return formatDate(new Date(UNIX_timestamp.toDate()))
    }
    const printDocument = (doc) => {
        const app = initializeApp(firebaseConfig);
        const db = getFirestore(app);
        const q = query(collection(db, 'clients'), where('id', '==', data));
        onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((client) => {
                let addtime = client.data().addtime;
                let user = client.data().name;
                let phone = client.data().phone;
                let device = client.data().device;
                let imei = client.data().imei;
                let cost = client.data().cost;
                let breaking = client.data().breaking;
                let completed = client.data().completeness;
                let status = client.data().status;
                let url = `${serverConfig.url}/print.php?doc=${doc}&id=${data}&user=${user}&phone=${phone}&device=${device}&complected=${completed}&status=${status}&imei=${imei}&breaking=${breaking}&cost=${cost}&addtime=${timeConverter(addtime)}`;
                window.open(url, '_blank');
                handleClose();
            });
        });
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="docUserDialogInCRM"
            >
                <DialogTitle>Распечатать документы</DialogTitle>
                <DialogContent>
                    <DialogContentText id="docUserDialogInCRM">
                        <p style={{ marginBottom: 25 }}>Выберите какой документ вы хотите распечатать:</p>
                        <Stack
                            direction="row"
                            divider={<Divider orientation="vertical" flexItem />}
                            spacing={3}
                        >
                            <Item onClick={() => printDocument(2)}>
                                <Box component="img" src="/static/icons/2.svg" style={{ width: "100%", cursor: "pointer" }} />
                            </Item>
                            <Item onClick={() => printDocument(3)}>
                                <Box component="img" src="/static/icons/3.svg" style={{ width: "100%", cursor: "pointer" }} />
                            </Item>
                            <Item onClick={() => printDocument(1)}>
                                <Box component="img" src="/static/icons/1.svg" style={{ width: "100%", cursor: "pointer" }} />
                            </Item>
                        </Stack>
                        <p style={{ fontSize: 10, marginTop: 30, marginBottom: 10 }}>ID печати: <strong>{data && data}</strong></p>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}
