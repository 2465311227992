/* eslint-disable */

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import {FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography, useTheme} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';

const steps = ['Новый пользователь', 'Статус'];
const names = ['В Работе', 'Завершен', 'В Ожидании'];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddUserForm({ closeWindow, saveData }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const theme = useTheme();
  const [personStatus, setPersonStatus] = React.useState([]);
  const [personName, setPersonName] = React.useState('');
  const [personPhone, setPersonPhone] = React.useState('');
  const [personDevice, setPersonDevice] = React.useState('');
  const [personImei, setPersonImei] = React.useState('');
  const [personBreaking, setPersonBreaking] = React.useState('');
  const [personCost, setPersonCost] = React.useState('');
  const [personCompleteness, setPersonCompleteness] = React.useState('');
  const isStepOptional = (step) => step === 1;

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleSend = () => {
    // Отправка
    saveData([{
      name: personName,
      phone: personPhone,
      deviceDanger: personDevice,
      completeness: personCompleteness,
      breaking: personBreaking,
      imei: personImei,
      cost: personCost,
      status: personStatus[0]
    }])
    handleReset();
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    closeWindow(false);
    // Очистка
    setPersonStatus([]);
    setPersonName('');
    setPersonPhone('');
    setPersonDevice('');
    setPersonCompleteness('');
    setPersonImei('');
    setPersonCost('');
    setPersonBreaking('');
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonStatus(
        // On autofill we get a the stringifies value.
        typeof value === 'string' ? value.split(',') : value,
    );
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
          personName.indexOf(name) === -1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
    };
  }

  const SetupNames = (e) => setPersonName(e.target.value)
  const SetupPhone = (e) => setPersonPhone(e.target.value)
  const SetupDevice = (e) => setPersonDevice(e.target.value)
  const SetupImei = (e) => setPersonImei(e.target.value)
  const SetupCost = (e) => setPersonCost(e.target.value)
  const SetupBreaking = (e) => setPersonBreaking(e.target.value)
  const SetupCompleteness = (e) => setPersonCompleteness(e.target.value)

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        handleReset()
      ) : (
        <>
        <Box sx={{pt: 2 }}>
          {activeStep === 0 && (
              <>
              <Typography style={{marginTop: 15, marginBottom: 10}} variant="p" component="p">
                Давайте добавим клиента
              </Typography>
              <FormControl sx={{width: '100%'}}>
                <TextField label="Ф.И.О клиента" value={personName} style={{marginBottom: 5}} onChange={SetupNames} variant="outlined" />
                <TextField label="Телефон" value={personPhone} style={{marginBottom: 5}} onChange={SetupPhone} variant="outlined" />
                <TextField label="Устройство" value={personDevice} minRows={1} onChange={SetupDevice} multiline style={{marginBottom: 5}} variant="outlined" />
                <TextField label="Предварительная стоимость" InputProps={{endAdornment: <InputAdornment position="end">₽</InputAdornment>,
                }} value={personCost} onChange={SetupCost} style={{marginBottom: 5}} variant="outlined" />            
                <TextField label="IMEI / Серийный номер" value={personImei} minRows={1} onChange={SetupImei} multiline style={{marginBottom: 5}} variant="outlined" />
                <TextField label="Заявленные неисправности" value={personBreaking} minRows={2} onChange={SetupBreaking} multiline style={{marginBottom: 5}} variant="outlined" />
                <TextField label="Комплектность" value={personCompleteness} minRows={2.6} onChange={SetupCompleteness} multiline style={{marginBottom: 5}} variant="outlined" />
              </FormControl>
                <Typography style={{marginTop: 5, marginBottom: 5, fontSize: 12, fontStyle: 'italic', opacity: '0.7'}} variant="p" component="p">
                  Заполните вышеуказанные поля, они являются обязательными
                </Typography>
              </>
          )}
          {activeStep > 0 && (
              <>
              <Typography style={{marginTop: 25, marginBottom: 10}} variant="p" component="p">
                Выберите статус клиента в базе
              </Typography>
                <FormControl sx={{width: '100%'}}>
                  <InputLabel id="statusSelect">Статус</InputLabel>
                    <Select
                      labelId="statusSelect"
                      id="statusSelect"
                      value={personStatus}
                      onChange={handleChange}
                      input={<OutlinedInput label="Статус" />}
                      MenuProps={MenuProps}
                    >
                  {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, personStatus, theme)}
                      >{name}
                      </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <Typography style={{marginTop: 5, marginBottom: 25, fontSize: 12, fontStyle: 'italic', opacity: '0.7'}} variant="p" component="p">
                  Вам необходимо обязательно установить статус нового клиента в базе,<br/> этот параметр является обязательным и меняется в зависимости от <br/>исполнения обязательств перед клиентом
                </Typography>
              </>
              )
            }
        </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {activeStep === 0 && (
              <Button color="error" onClick={handleReset} sx={{ mr: 1 }}>
                Отменить
              </Button>
            )}
            {activeStep > 0 && (
              <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                Изменить
              </Button>
            )}
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep !== steps.length - 1 &&
              <Button onClick={handleNext} disabled={personName.length === 0 && personPhone.length === 0 && personDevice.length === 0} style={{ textTransform: 'none' }}>
                Сохранить и добавить
              </Button>
            }
            {activeStep === steps.length - 1 &&
              <Button onClick={handleSend} disabled={activeStep === steps.length - 1 && personStatus.length < 1} style={{ textTransform: 'none' }}>
                Завершить
              </Button>
            }
          </Box>
        </>
      )}
    </Box>
  );
}
